<template>
  <div class="">
    <div class="bg-white">
      <div class="d-flex justify-end py-5 mx-15">
        <v-btn
          color="error"
          class="btn-close mr-4"
          href="javascript:window.open('','_self').close();"
        >
          {{ $t("buttons.close") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="createBooking()"
          :disabled="!checkPerUser"
        >
          保存
        </v-btn>
      </div>
      <div class="mx-16 px-3">
        <h2>ステータス</h2>
        <div class="d-flex mt-8">
          <div>
            <h6>ステータス</h6>
            <h3 v-if="booking && booking.computed && booking.computed.status">{{booking.computed.status}}</h3>
          </div>
          <v-btn v-if="booking && booking.computed && booking.computed.status && booking.computed.status !== 'キャンセル'"
            small
            class="my-auto ml-4 bg-red"
            @click="getListCancelPenalty()"
            :disabled="!checkPerUser"
            >キャンセル</v-btn
          >
        </div>
        <v-checkbox
           v-model="checkbox"
           label="部屋割を固定する"
           disabled
        ></v-checkbox>
        <h6>予約バーに表示させる備考内容</h6>
        <v-text-field class="w-20"
            @change="getReservationBarNote($event)"
            v-model="bookingBarNote"
            single-line
            :disabled="!checkPerUser"
        ></v-text-field>
        <h2>基本情報</h2>
        <div class="mt-6 w-75">
          <div class="d-flex">
            <div class="w-8">
              <div>
                <h6 class="text-small ml-8">{{ $t("common.checkIn") }}</h6>
              </div>
              <div>
                <v-icon class="mr-1">mdi-calendar-month</v-icon>
                <span
                  class="bg--btn-white ml-1"
                  >{{formatDate(checkInDate)}}</span>
              </div>
            </div>
            <div  class="d-flex align-end my-auto px-5">~</div>
            <div class="w-8">
              <div>
                <h6 class="text-small">{{ $t("common.checkOut") }}</h6>
              </div>
              <div>
                <span
                  class="bg--btn-white ml-1"
                  >{{formatDate(checkOutDate)}}</span>
              </div>
            </div>
            <div  class="d-flex align-end mb-1 px-3">
              <h5 class="text-size-normal">
              {{numNight}} {{ $t("contractDetail.night") }} {{numDay}} {{$t("common.day")}}</h5>
            </div>
            <div class="my-auto w-50">
              <v-btn
                v-if="!booking.permanentBlock"
                small
                class="my-auto ml-4 bg-red"
                @click="showSelectFrame()"
                :disabled="!checkPerUser"
                >チェックイン・アウト日変更</v-btn
              >
            </div>
          </div>
        </div>
        <div class="d-flex mt-8">
          <div>
            <h6>施設</h6>
            <h3>{{facility}}</h3>
          </div>
          <v-btn
            small
            class="my-auto ml-4 bg-prm"
            @click="showFloorPlanPdfUrl()"
            :disabled="!checkPerUser"
            >施設間取り図を開く</v-btn
          >
        </div>
        <div class="pt-6">
            <h6>部屋タイプ</h6>
            <h3>{{roomTypeName}}</h3>
        </div>
        <v-select class="w-20 mt-5"
          v-model="roomId"
          :items="getRoomListReserBlock"
          item-text="name"
          item-value="id"
          label="部屋"
          :disabled="!checkPerUser"
        ></v-select>
      </div>
    </div>
    <SettingCancelDialog
      :title="$t('buttons.cancel')"
      :visible="visible === 1"
      v-if="visible === 1"
      last-day-cancel
      @close="visible = -1"
    />

    <SettingMoveDialog
      v-if="showMoveDialog"
      :bookingId="booking.id"
      :oldCheckInDate="booking.checkInDate"
      :oldCheckOutDate="booking.checkOutDate"
      :bookingTypeId="booking.bookingTypeId"
      @close="showMoveDialog = false"
    />
  </div>
</template>

<script>
// import ReservationTabs from './ReservationTabs.vue'
import moment from 'moment'
import SettingMoveDialog from '@/views/dashboard/contractDetail/settingMoveDialog.vue'
import SettingCancelDialog from '../../contractDetail/settingCancelDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { CALCULATE_BOOKING_CANCEL_PENALTY } from '@/api/graphql/bookingDetail/setting-cancel'
import { formatDate } from '@/constants/functions'
import {
  BOOKING
  // ROOM_LIST,
  // BOOKING_TYPES_LIST
} from '@/api/graphql/bookingDetail/booking-detail-basic'
import { ROOM_TYPE_LIST } from '@/api/graphql/facility/facility-room-type'
import gql from 'graphql-tag'
export default {
  components: { SettingCancelDialog, SettingMoveDialog },
  data () {
    return {
      listCancelBooking: [],
      facilityId: null,
      checkInDate: this.$route.query.checkInDate,
      checkOutDate: moment(this.$route.query.checkInDate).add(parseInt(this.$route.query.night) - 1, 'days').format('YYYY-MM-DD'),
      roomTypeName: this.$route.query.roomTypeName,
      facility: null,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      checkbox: true,
      showMoveDialog: false,
      booking: {},
      numNight: 0,
      numDay: 0,
      roomId: null,
      bookingBarNote: '',
      roomFixed: true,
      visible: -1
    }
  },
  watch: {
    getStatus () {
      this.getBookingList()
    }
  },
  mounted () {
    this.getBookingList()
    document.title = `ブロック_予約ID_${this.bookingId}`
  },
  computed: {
    ...mapGetters(['getRoomListReserBlock', 'getBookingReserBlock', 'getStatus']),
    bookingId () {
      return parseInt(this.$route.query.id)
    }
  },
  methods: {
    ...mapActions(['cancelBooking', 'roomListReservation', 'updateReservationBlock']),
    ...mapMutations(['setBookingCancel', 'setRoomListReserBlock', 'setBookingReserBlock', 'setLoadingOverlayHide', 'setLoadingOverlayShow']),

    async getListCancelPenalty () {
      const data = await this.$gqlLoading({
        query: gql`${CALCULATE_BOOKING_CANCEL_PENALTY}`,
        variables: {
          bookingId: this.bookingId
        },
        fetchPolicy: 'no-cache'
      })
      this.listCancelBooking = data.data.calculateBookingCancelPenalty.penalties
      this.actionUpdate()
    },
    actionUpdate () {
      const arrUpdate = []
      for (let i = 0; i < this.listCancelBooking.length; i++) {
        const item = {
          penaltyRate: this.listCancelBooking[i].penaltyRate,
          stayDate: this.listCancelBooking[i].stayDate
        }
        arrUpdate.push(item)
        const bookingCancel = {
          penaltyRates: arrUpdate
        }
        this.setBookingCancel({ bookingCancel: bookingCancel })
      }

      this.cancelBooking()
    },

    formatDate,
    getNumbertDay () {
      var timeNight = Math.abs(moment(this.checkInDate) - moment(this.checkOutDate))
      var timeDay = Math.abs(moment(this.checkInDate) - moment(this.checkOutDate)) + 1
      this.numNight = Math.ceil(timeNight / (1000 * 3600 * 24))
      this.numDay = Math.ceil(timeDay / (1000 * 3600 * 24))
    },
    getReservationBarNote (event) {
      this.bookingBarNote = event
    },
    async createBooking () {
      const reservationData = {
        bookingId: this.bookingId,
        roomId: this.roomId,
        roomFixed: this.roomFixed,
        bookingBarNote: this.bookingBarNote
      }
      console.log('****171*****', reservationData)
      await this.updateReservationBlock(reservationData)
    },
    showSelectFrame () {
      this.showMoveDialog = true
    },
    showFloorPlanPdfUrl () {
      // console.log('aaaaa', this.booking.room.roomType.facility.floorPlanPdfUrl)
      if (this.facility && this.booking.room.roomType.facility.floorPlanPdfUrl) {
        window.open(this.booking.room.roomType.facility.floorPlanPdfUrl, '_blank')
      }
    },
    async getBookingList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING}`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.booking = data.data.booking
          this.checkOutDate = this.booking.checkOutDate
          this.checkInDate = this.booking.checkInDate
          this.bookingBarNote = this.booking.bookingBarNote
          this.getNumbertDay()
          this.roomListReservation(data.data.booking.room.roomType.id)
          if (this.booking.room) {
            this.roomId = this.booking.room.id
            if (this.booking.room.roomType) {
              if (this.booking.room.roomType.facility.id) {
                this.facilityId = this.booking.room.roomType.facility.id
                this.facility = this.booking.room.roomType.facility.name
                this.roomTypeName = this.booking.room.roomType.name
                this.roomType = this.booking.room.roomType
                this.getRoomTypeList(this.booking.room.roomType.facility.id)
              }
            }
          }
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    async getRoomTypeList (id) {
      await this.$apollo
        .query({
          query: gql`${ROOM_TYPE_LIST}`,
          variables: {
            facilityId: id
          }
        })
        .then((data) => {
          this.roomTypeList = data.data.roomTypeList
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

}
</script>

<style scoped lang="scss">
  .w-50{
    min-width: 50%;
  }
  .w-20{
    width: 20%;
  }
  .w-8{
    min-width: 8%;
  }
  .w-75{
    min-width: 75%;
  }
  span,
  h6{
    font-size: 10px !important;
    font-weight: 600;
    color: #000 !important;
  }
  h5{
    font-size: 12px !important;
    color: #000000 !important;
    font-weight: 500;
  }
  h3{
    font-size: 14px !important;
    color: #444444 !important;
    font-weight: 500;
  }
  h4{
    font-size: 16px !important;
    color: #000;
    font-weight: 500;
  }
  h2{
    font-size: 20px !important;
    color: #0C6786 !important;
    font-weight: bold;
  }
  .bg-red{
    border: 0.5px solid #BF3D34 !important;
    background: #fff !important;
    border-radius: 4px;
    color: #C92D2D !important;
    font-size: 10px !important;
  }
  .bg-prm{
    border: 0.5px solid #1873D9 !important;
    color: #1873D9 !important;
    border-radius: 4px;
    font-size: 10px !important;
    background: #fff !important;
  }
  .col-2{
    margin-left: -40px !important;
    max-width: 11%;
  }
  .col-3{
    max-width: 21%;
  }
  .col-1 {
    margin-left: -25px !important;
    max-width: 4%
  }
  .mdi-calendar-month{
    color: #333333 !important;
  }
  .bg-white{
    background: #FFFFFF;
  }
  ::v-deep{
    .v-select__selection--comma{
      font-size: 14px !important;
      color: #444444 !important;
      font-weight: 500;
      .v-label{
        font-size: 10px !important;
        font-weight: 600 !important;
        color: #000 !important;
      }
    }
    .text-red{
      .v-icon__svg{
       color: #C92D2D !important;
      }
      .v-input__slot{
        color: #C92D2D !important;
        &:before{
          border-color: #C92D2D !important;
        }
      }
    }
    .v-input--checkbox {
        .theme--light.v-label{
          color: #000000 !important;
          font-size: 12px !important;
          font-weight: 500;
        }
    }
    .v-input--checkbox {
      .primary--text{
        color: #AAAAAA !important;
      }
    }
    .v-select__slot{
      .v-label{
        font-size: 10px !important;
        font-weight: 600 !important;
        color: #000 !important;
      }
    }
  }

</style>
