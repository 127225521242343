<template>
  <div class="px-main --dark py-9 px-16">
    <h1 class="my-auto mb-5">
      ブロック_予約ID_{{ bookingId }}
    </h1>
    <template>
      <div class="my-9">
        <v-tabs v-model="tab" class="t-tab-custom">
          <v-tab v-for="(item, i) in items" :key="i" :href="'#'+item.tabUrl" :to="item.to">{{
            item.title
          }}</v-tab>
        </v-tabs>
         <v-tabs-items :value="tab" touchless :class="isMinScreen ? 'min-width-screen' : ''" class="mt-9">
           <v-tab-item value="Block-Reservation">
                <ReservationBlock />
            </v-tab-item>
            <v-tab-item value="operation-change-history">
                <OperationChangeHistory />
            </v-tab-item>
            <v-tab-item value="canceled">
                <Canceled />
            </v-tab-item>
        </v-tabs-items>
        <!-- <slot name="default" /> -->
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import Canceled from '../../contractDetail/canceled.vue'
import OperationChangeHistory from '../../contractDetail/operationChangeHistory.vue'
import ReservationBlock from './ReservationBlock.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'BaseGuestRoomDailyReport',
  data () {
    return {
      type: null,
      bookingId: this.$route.query.id,
      isMinScreen: false,
      items: [
        {
          title: '基本情報',
          tabUrl: 'Block-Reservation'

          // to: '/booking/Block-Reservation'
        },
        {
          title: '操作変更履歴',
          // to: '',
          tabUrl: 'operation-change-history'
        },
        {
          title: '削除',
          // to: '',
          tabUrl: 'canceled'
        }
      ]
    }
  },

  async created () {
    await this.getBookingList()
  },

  mounted () {
    this.setWidthScreen()
  },
  components: {
    OperationChangeHistory,
    Canceled,
    ReservationBlock
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
        this.setWidthScreen()
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  methods: {
    ...mapMutations(['setLoadingOverlayHide', 'setLoadingOverlayShow']),
    setWidthScreen () {
      this.isMinScreen = this.tab === 'operation-change-history'
    },

    async getBookingList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`query ($id: Int!) {
            booking(id: $id) {
            bookingGroupId
            id
            bookingTypeId 
            bookingType { id name code }
          }
        }`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.type = data.data.booking.bookingType.code
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep{
  .theme--light.v-tabs > .v-tabs-bar{
    background: none !important;
  }

  .t-tab-custom .v-tab {
    padding: 0 32px !important;
  }
}
</style>
